import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { GatsbyImageFocused } from '@/features/common/'
import { absoluteFill, mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.StatFragment | null
  heading?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  layout?: 'HOME' | 'PAGE'
  color?: 'BLACK' | 'RED' | 'WHITE'
}

export const Stat = ({
  data,
  heading = 'h3',
  layout = 'PAGE',
  color = 'BLACK',
  ...props
}: Props): JSX.Element => {
  const { number, image, description } = data || {}
  const Heading = heading
  const getImage = () => {
    switch (color) {
      case 'BLACK':
        return data?.image?.black
      case 'WHITE':
        return data?.image?.white
      case 'RED':
        return data?.image?.red
    }
  }
  const styles = {
    container: css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      ${color === 'BLACK' &&
      css`
        background: #000;
        color: #fff;
      `}
      ${color === 'RED' &&
      css`
        color: #fff;
      `}
      ${color === 'WHITE' &&
      css`
        background: #fff;
        color: #000;
      `}
    `,

    image: css`
      ${absoluteFill};
      ${color === 'BLACK' &&
      css`
        opacity: 0.5;
      `}
      ${color === 'WHITE' &&
      css`
        opacity: 0.3;
      `}
    `,
    stat: css`
      width: 100%;
      font-size: var(--fs-24);
      font-weight: 275;
      margin: 1.5em;
      padding: 0.75em 0;
      box-sizing: border-box;
      position: relative;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      ${layout === 'HOME' &&
      css`
        border-top: 2px solid ${colors.redLight};
        border-bottom: 2px solid ${colors.redLight};
        ${mq().m} {
          margin: 0.75em;
          font-size: var(--fs-21);
        }
        ${mq().s} {
          font-size: max(3vw, 1rem);
          font-weight: 450;
        }
      `}
    `,
    number: css`
      font-size: min(
        10vw,
        calc(20vw / ${Math.pow(number?.length || 0, 0.75)})
      );
      line-height: 0.8;
      font-weight: 400;
      ${layout === 'HOME' &&
      css`
        font-size: min(
          10vw,
          calc(20vw / ${Math.pow(number?.length || 0, 0.75)})
        );
        ${mq().ls} {
          font-size: min(
            12.5vw,
            calc(30vw / ${Math.pow(number?.length || 0, 0.75)})
          );
        }
      `}
      ${layout === 'PAGE' &&
      css`
        ${mq().ms} {
          font-size: var(--fs-108);
        }
      `}
    `,
    description: css`
      font-family: var(--ff-body);
      letter-spacing: 0.05em;
      margin-top: 0.333em;
      line-height: 1.25;
    `,
  }
  return (
    <div
      css={styles.container}
      {...props}
    >
      {getImage() && (
        <GatsbyImageFocused
          css={styles.image}
          image={getImage()}
          aspectRatio={1}
          data={image}
        />
      )}
      <Heading css={styles.stat}>
        <span css={styles.number}>{number} </span>
        <span css={styles.description}>{description}</span>
      </Heading>
    </div>
  )
}

export const StatFragment = graphql`
  fragment Stat on DatoCmsStat {
    number
    description
    image {
      black: gatsbyImageData(
        width: 720
        sizes: "(max-width: 720px) 50vw, 33vw "
        imgixParams: {
          ar: "1:1"
          fit: "crop"
          crop: "focalpoint"
          sat: -100
          con: 50
        }
      )
      white: gatsbyImageData(
        width: 720
        sizes: "(max-width: 720px) 50vw, 33vw"
        imgixParams: {
          ar: "1:1"
          fit: "crop"
          crop: "focalpoint"
          sat: -100
          con: 50
        }
      )
      red: gatsbyImageData(
        width: 720
        sizes: "(max-width: 720px) 50vw, 33vw "
        imgixParams: {
          ar: "1:1"
          fit: "crop"
          crop: "focalpoint"
          sat: -100
          con: -10
          blendColor: "#d92636"
          blendAlpha: 100
          blendMode: "multiply"
        }
      )
      ...ImageFocalData
    }
  }
`
